.activeSelected {
  background-color: var(--brandHover) !important;

  height: 50px;
  width: 100px;

}

.FaIcon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.navBarSearch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.navBarSearch > * + * {
  margin-left: 2px;
}



@media (max-width: 991px) {
     
    .activeSelected {
        /* background-color: var(--playButton) !important; */
      
        height: 50px;
        width: 200px;
        min-width: 200px;
      
      }
      
  }