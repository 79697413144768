.subTitle {
  font-weight: bold;
  color: var(--mainWhite);
}

.item {
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 1px;
  border-radius: 12px;
  padding: 12px;
}

.itemName {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  padding-left: 12px;
}

.innerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.innerValue {
  width: 50%;
}

.innerValueTickets {
  display: flex;
  flex-direction: column;
  width: 50%;
}