.CategoryItem_main {
	/* margin: 30px auto; */
	margin-bottom: -30px;
	/* width: 90%; */
}

.CategoryItem_main_info {
	display: flex;
	align-items: center;
	padding: 10px 0px;
	justify-content: space-between;
	width: 95%;
}

.CategoryItem_main_link {
	height: 100%;
	border: none;
	color: var(--brand);
	text-decoration: none;
	font-weight: 400;
	line-height: 140%;
	transition: 0.3s ease;
}

.CategoryItem_main_link:hover {
	cursor: pointer;
	color: var(--brandPressed);
}

.CategoryItem_main_info_title {
	color: var(--mainBlack);
	line-height: 28px;
	margin: 0px 10px;
}

.CategoryItem_main_info_title:hover {
	cursor: pointer;
}

.CategoryItem_main_info_title,
.CategoryItem_main_info button {
	padding: 30px 0px;
}

.CategoryItem_main_info_title {
	/* padding: 30px 0px; */
	padding: 0;
	background: var(--brand) !important;

	min-width: 200px;
	padding: 10px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 700;
	line-height: 140%;
	transition: 0.3s ease;
}
