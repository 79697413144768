.payment {
  width: 88%;
  margin: 30px auto;
}

.paymentTitle {
  color: var(--mainBlack);
  /*  */
  margin: 10px auto;
  text-align: left;
}

.paymentHelp {
}

.paymentUserNotLoggedIn {
  padding: 20px 0 0;
  color: var(--red);
}

.paymentMethodsContainer {
  margin-top: 30px;
  position: relative;
}

.paymentHideMethods {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
}

.paymentMethodsTitle {

  margin: 30px auto 10px;
  color: var(--mainBlack);
  /* font-size: 1.5rem; */
  text-align: left;
}

.paymentMethodHelp {

  margin: 0 auto;
}

.paymentMethodError {

  margin: 0 auto;
  color: var(--red);
}

.paymentMethods {

  /* margin: 10px auto; */
  display: flex;
  /* flex-direction: column; */
  border: 1px solid var(--brand);
  padding: 20px 20px 10px;
  width: 500px;
  /* height: 158px; */
  margin: 50px 0;
  gap: 20px;
  justify-content: center;
  /* align-items: center; */
  height: 158px;
  border-radius: 12px;
}
.paymentMethods_Voucher{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--brand);
  padding: 20px 20px 10px;
  width: 500px;
  /* height: 158px; */
  margin: 50px 0;
  gap: 20px;
  border-radius: 12px;


}


@media (max-width:550px) {
  
  .paymentMethods {

    display: flex;
    flex-direction: column;
    width: auto;
    
    
  }
  .paymentMethods {

    
    height: auto;
  }
}