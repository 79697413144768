.EditorChoice {
  /* background: url('../../images/backgroundImageEditorChoice.png'); */
  background-position: center !important  ;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-top: 15px !important;
  padding-bottom: 110px !important;
  width: 100%;
  /* height: 100%; */
  color: white;
}

.EditorChoicePrimary {
  width: 90%;
  margin: auto;
}
.EditorChoice h2 {
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}
.starIcon {
  padding-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--white);
}

/* media Queeries */
@media (max-width: 750px) {
  .EditorChoicePrimary {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}
