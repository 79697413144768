.ChannelsMainPage {
  padding-bottom: 30px;
  background-color: var(--mainWhite);

  color: black;
}
.ChannelsMainPage__main {
  margin: auto;
  width: 88%;
  /* padding-top: 40px; */
}
.ChannelsMainPage_Heading {
  /* background-color: black; */
  color: var(--mainBlack);
  margin: 30px 0;
}
.ChannelsMainPage_primary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%),300px));
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
}
.ChannelsMainPage_secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:20px 10px; */
  background-color: var(--card);
  height: 100%;
  flex-direction: column;
 /* margin-bottom: 20px; */
}
.ChannelsMainPage_secondary > * {
  flex: 1;
  height: 100%

  ;
}
.ChannelsMainPage_secondary > * + * {
  /* margin-top: 20px; */
  
}
.textDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
   
}
.textDescription > * + * {
  margin-top: 30px;
}

.channelName {
  /* font-size: 1rem; */
  color: var(--brandHover);
}
.channelName:hover {
  /* color: var(--brandHover); */
  /* cursor: pointer; */
}
.channeltext {
  /* font-size: 1rem; */
  color: var(--mainBlack);
}

.channelButton {
  padding: 15px 40px;
  background-color: var(--brandHover);
  color: var(--mainWhite);
  border: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* font-size: 15px; */
}
.channelButton:hover {
  background-color: var(--brand);
  transition: 0.5s ease all;
  cursor: pointer;
}
.channelsLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 70px 0;
}
.channelsLink a {
  padding: 10px 25px;
  /* font-size: 1.25rem; */
  cursor: pointer;
  text-decoration: none;
  /* font-weight: 500; */
  color: var(--mainBlack);

  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 0;
}

.channelsLink a:hover,
.channelsLink a.active,
.channelsLink a:active {
  background-color: transparent;
  border-left: 4px solid var(--brandHover);
  border-radius: none;
  color: var(--brandHover);
  outline: none;
}
.channel{
  display: flex;
  flex-direction: column;
}