/* .tosContainer {
  margin: 5% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--mainBlack);
}

.padded {
  padding-left: 1%;
}
.padded li {
  padding: 5px 0;
}

.tosMainTitle_p {
  line-height: 28px;
}
.tosTitle {
  font-weight: 700;
  padding-bottom: 10px;
}
.tosMainTitle {
  padding-bottom: 10px;
  font-weight: 700;
}
.tosMainTitle_p a {
  color: var(--brandHover);
} */

.tosContainer {
	margin: 5% auto 2% auto;
	width: 80%;
	flex-grow: 1;
	color: var(--mainBlack);
	padding: 20px; /* consistent padding for the container */
}

.padded {
	padding-left: 1%;
}

.padded li {
	padding: 5px 0;
}

.tosMainTitle_p {
	line-height: 28px;
	margin: 10px 0; /* consistent margin for paragraphs */
}

.tosTitle {
	font-weight: 700;
	padding-bottom: 10px;
	margin-top: 20px; /* consistent spacing above each title */
}

.tosMainTitle {
	padding-bottom: 10px;
	font-weight: 700;
	margin-top: 20px; /* consistent spacing for main title */
}

.tosMainTitle_p a {
	color: var(--brandHover);
}

.tosContainer img {
	height: auto; /* maintain aspect ratio */
	width: 100%;
	max-width: 991px;
	margin: 0 auto;
	padding: 10px 0; /* add padding around image for consistency */
}
