

.HeaderTitle {
    height: 100%;
    border: none;

    text-decoration: none;
    font-weight: 700;
    line-height: 140%;
    transition: 0.3s ease;

    background-color: var(--brand) !important;

    min-width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mainWhite);
}

.HeaderTitleAdmin {
    background-color: var(--mainWhite) !important;
    color: var(--mainBlack) !important;
    padding: 10px 0 ;
    justify-content: flex-start;
}