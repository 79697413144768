.navBarRow {
	display: flex;
	width: 100%;
	align-items: center;
	font-weight: 400;
	justify-content: space-between;
}

.navBar {
	display: grid;
	grid-template-columns: 0fr 1fr;
	height: 60px;
	width: 88%;
	align-items: center;
	color: #ffffff;
	z-index: 5;
	margin: 0 auto;
	/* padding: 0 20px; */
	min-height: 60px;
}
.navBar:first-child a {
	z-index: 9999999 !important;
}
.navBar a {
	text-decoration: none;
	list-style: none;
	color: #ffffff;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
}

.navBarRow_primary {
	flex: 3;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.navBarRow_primary li,
.navRight li {
	text-decoration: none;
	list-style: none;
	color: #ffffff;
}
.navBarRow_primary li a,
.navRight li a {
	text-decoration: none;
	list-style: none;
	color: #ffffff;
	position: relative;
}

.navRight {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	flex: 2;
	justify-content: flex-end;
	align-items: center;
}
.navRight > * + * {
	margin-left: 20px !important;
}

@media (max-width: 1200px) {
	.navBar {
		width: 100%;
		margin: 0 auto;
	}
}
@media (max-width: 991px) {
	.navBar {
		justify-content: space-between;
	}
	.navBar:first-child a {
		width: auto;
		color: #ffffff !important;
	}

	.navBarRow {
		display: flex;
		width: 100%;
		align-items: center;
		font-weight: 400;
		justify-content: flex-end;
		max-height: 100%;
		/* overflow: hidden; */
	}
	.navBarRow_primary {
		flex-direction: column;
		gap: 20px;
	}
	.navRight {
		width: 100%;
		justify-content: center;
	}
	.navBarRow_primary li a,
	.navRight li a {
		text-decoration: none;
		list-style: none;
		color: var(--mainBlack);
		position: relative;
		height: 50px;
		width: 200px;
		min-width: 200px;
	}
	.navBar {
		color: var(--mainBlack);
	}
}
