.ViewedSectionPrimary {
	overflow: hidden;
	background-color: var(--mainWhite);
	color: var(--mainBlack);
}
.ViewedSectionSecondary {
	width: 80%;
	margin: auto;
}

.ViewedSectionSecondary h2 {
	text-align: center;
	padding-top: 40px;
	font-weight: 400;
}

.ViewedSectionSecondary h2:hover {
	color: var(--mainYellow);
}
.ViewedSectionSecondary h2:hover::after {
	background-color: var(--mainBlack);
}

.categoryList {
	width: 50%;
	margin: auto;
	padding-bottom: 30px;
}

.ViewedSectionSecondary .categoryList ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}

.categoryList ul li {
	color: #999999;
	font-size: 0.75rem;
	padding-top: 12px;
}
.ViewedSectionSecondary__3videos {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding-bottom: 30px;
	gap: 25px;
}

.ViewedSectionSecondary__3videos__image,
.ViewedSectionSecondary__2videos__image {
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;
	border: 1px solid var(--brand);
	border-radius: 16px;
	display: flex;
}
.ViewedSectionSecondary__3videos__image img {
	width: 100%;
	height: 100%;
	/* border-radius: 16px; */
}

.ViewedSectionSecondary__3videos__image_overlay {
	position: absolute;
	bottom: 20px;
	color: var(--mainWhite);
	width: 100%;
}
.wrapper_bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.wrapper_bottom_likes,
.wrapper_bottom_duration {
	background-color: var(--mainBlack);
	color: var(--mainWhite);
	padding: 5px;
	font-size: 0.75rem;
	font-weight: 800;
}

.wrapper_bottom_likes {
	margin-left: 10px;
}
.wrapper_bottom_duration {
	margin-right: 10px;
}
.ViewedSectionSecondary__2videos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 25px;
	padding-bottom: 30px;
	margin-bottom: 30px;
}
.secondaryClassName {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ViewedSectionSecondary__3videos__main img,
.ViewedSectionSecondary__2videos__main img {
	-webkit-transition: 0.6s ease;
	transition: 0.6s ease;
	width: 100%;
}
.ViewedSectionSecondary__3videos__image_Overlay {
	position: absolute;
	background: var(--cardbackground);
	color: var(--brand);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: all 0.5s;
	border-radius: 16px;
}
.ViewedSectionSecondary__3videos__image_Overlay_blur {
	backdrop-filter: blur(6px) drop-shadow(4px 4px 10px var(--playButton));
}

/* // animation of play button */
.ViewedSectionSecondary__3videos__image_Overlay > * {
	transform: translateY(20px);
	transition: transform 0.25s;
}
.ViewedSectionSecondary__3videos__image_Overlay:hover > * {
	transform: translateY(0);
}
.ViewedSectionSecondary__3videos__image_Overlay:hover {
	opacity: 1;
}
.ViewedSectionSecondary__3videos__videoicon_overlay {
	cursor: pointer;
	z-index: 2000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
.ViewedSectionSecondary__3videos__videoicon_overlay > * + * {
	margin-top: 20px;
}
.ViewedSectionSecondary__3videos__videoicon_overlay_view_all {
	background: var(--brand);
	color: var(--brandHover);
}
.ViewedSectionSecondary__3videos__videoicon_overlay_view_all:hover {
	background: var(--brandHover);
	color: var(--brand);
}

.ViewedSectionSecondary__2videos__main img {
	/* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.ViewedSectionSecondary__2videos__main:hover img {
	transform: scale(1.1);
}
.ViewedSectionSecondary__2videos__main:hover img {
	transform: scale(1.1);
}
.ViewedSectionSecondary__2videos__image:hover,
.ViewedSectionSecondary__3videos__image:hover {
	cursor: pointer;
}
.ViewedSectionSecondary__3videos__text_description {
	display: flex;
	flex-direction: column;

	gap: 4px;
}
.ViewedSectionSecondary__3videos__text_description_heading {
	font-weight: 500;
	color: var(--white);
	line-height: 20.8px;
}
.ViewedSectionSecondary__3videos__text_description_duration {
	display: flex;
	font-weight: 400;
	color: var(--silver);
	gap: 10px;
	line-height: 16.8px;
}
.ViewedSectionSecondary__3videos__text_description:hover {
	/* color: var(--mainYellow); */
	cursor: pointer;
}

.ViewedSectionSecondary__3videos__text_actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;
	font-size: 0.875rem;
	color: #999999;
}
.ViewedSectionSecondary__3videos__text_actions p {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.ViewedSectionSecondary__3videos__text_description_status {
	color: var(--brand);
	line-height: 15.4px;
	font-weight: 400;
	font-size: var(--font11);
}
.ViewedSectionSecondary__3videos__text_description_status:hover {
	color: var(--brandHover);
}

/* media Queeries */
@media (max-width: 750px) {
	.ViewedSectionSecondary {
		width: 100%;
		margin: 0 10px 0 10px;
	}
}
@media (max-width: 550px) {
}
