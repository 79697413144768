.selected {
	color: var(--brand) !important;
}

.navBarBTN {
}

.navBarBTN:hover {
	text-decoration: underline;
	cursor: pointer;
}
.categories_info {
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	color: white;
}
.navBarSiteLogo {
	height: 75px;
	/* width: 75px; */
	width: auto;
	/* margin-top: -150px; */
	position: absolute;
	top: -85px;
}

.navBarSiteLogo:hover {
	cursor: pointer;
}
.containerNavbar {
	background-color: var(--brand);
	margin-top: 70px;
}

.categoriesDropdown {
	flex-direction: column;
	position: absolute;
	background: var(--mainWhite) !important;

	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 1rem;
	z-index: 100000000000;
	width: 150px;

	justify-content: flex-start;
	align-items: flex-start;
	padding-top: 0px;
	margin-top: 0px;
}
.categoriesDropdown > * + * {
	margin-top: 10px;
}
.categoriesDropdown a {
	text-decoration: none;
	color: var(--brand);
}
.categoriesDropdown a:hover {
	text-decoration: none;
	background-color: var(--background);
}
.categoriesDropdown li:hover {
	text-decoration: none;
	color: var(--white) !important;
	background-color: var(--brandHover);

	cursor: pointer;
}
.container {
	display: flex;
	flex-direction: column;
	/*background: linear-gradient(to right, #000000, #171d31, #000000);*/
	background: transparent;
	/*
  background-image: linear-gradient(to right, #000000, #171d31, #000000);
  background-image: -moz-linear-gradient(to right, #000000, #171d31, #000000);*/
	min-height: 100vh;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.item {
	transition: all 0.3s ease-in-out;
}

.chosenCategoryGrid {
	flex-grow: 1;
	height: 100%;
}

.categoryItemTitle {
	background-color: #646464;
	color: white;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	font-size: 17px;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0.7rem 0.5rem 0.7rem;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	text-align: center;
	overflow: hidden;
	width: 15rem;
}

.categoryItemImg {
	object-fit: contain;
	display: block;
	max-height: 24rem;
	max-width: 16rem;
	/*min-height: 24rem;*/
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	cursor: pointer;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

/*
.categoryItem:hover {
  transform: scale(1.1);
}
*/

.detailsUpper {
	width: 100%;
	overflow: hidden;
	position: relative;
	padding-top: 25%;
	display: block;
	background-repeat: no-repeat;
	background-size: cover;
}

.detailsUpperBackground {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
}

.detailsUpperSmall {
	width: 80%;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.detailsUpperCover {
	width: 100%;
	vertical-align: middle;
}

.detailsPlayContainer {
	background-color: var(--brand);

	text-decoration: none;
	margin-bottom: 20px;
	color: var(--white);
	border: none;
	text-transform: uppercase;
	border-radius: var(--font12);
	line-height: 130%;
	display: flex;
	align-items: center;
	gap: 8px;
}

.detailsPlayContainer:hover .svg-play {
	fill: var(--brand);
}
.detailsPlayContainer:hover {
	cursor: pointer;
	background: var(--brandHover);
	color: var(--brand);
}

.svg-play {
	fill: white;
}

.detailsUpperCoverContainer {
	display: inline-block;
	width: 20%;
	position: absolute;
	left: 0;
	bottom: 0;
}

.categoryContent,
.similiarCategoryContent {
	margin: 0 auto;
	width: 95%;
	padding: 0 1rem 0 1rem;
}

.categoryContent::-webkit-scrollbar {
	display: none;
}

.banners {
	margin: 0 auto;
	width: 95%;
	padding: 0 1rem 0 1rem;
}

.catArrow {
	color: white;
	width: 2rem;
	position: relative;
	z-index: 100;
	align-self: center;
	cursor: pointer;
	font-size: 3rem;
	transition: all 0.3s ease-in-out;
}

.catArrow:hover {
	background-color: rgba(20, 20, 20, 5);
	-moz-box-shadow: 0 0 1rem #fff;
	-webkit-box-shadow: 0 0 1rem #fff;
	box-shadow: 0px 0px 1rem #fff;
	border-radius: 1rem;
}

.arrowRight {
	margin-left: auto;
	margin-right: 1rem;
}

.arrowLeft {
	margin-left: 1rem;
}

.categoryTitleContainer,
.similiarCategoryTitleContainer {
	margin: 1.5rem 0 1.5rem 0;
	color: #fcca13;
	font-family: "Nunito", sans-serif;

	font-size: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.similiarContainer {
	margin-top: 1.5rem;
}

.categoryTitle {
	text-align: center;
	cursor: default;
}

.titleHover:hover {
	cursor: pointer;
	text-decoration: underline;
}

.faqContainer {
	margin: 0 auto 0 auto;
	width: 80%;
	color: white;
	font-size: 16px;
	flex-grow: 1;
}

.playVideoContainer {
	/*
 margin: 0 auto 0 auto;
  width: 100%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
  */

	overflow: hidden;
	/*
  position: relative;
  padding-top: 56.25%;
  */
}
.maxContainer {
	width: 70%;
	margin: 0 auto;
}

.maxContainerPrimary {
	width: 100%;
	background: black;
	position: relative;
	/* padding: 10px 0; */
}

/*
.radiantPlayer {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.radiantPlayer .rmp-control-bar {
  margin-top: -200px;
}
*/

.searchColumn {
	display: flex;
	flex-direction: column;
	margin-right: 2%;
}

.searchButton {
	height: 50px;
}

.searchField {
	position: relative;
	padding: 17px 20px 17px 15px;
	outline: none;
	color: var(--background);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	font-weight: 500;
	max-height: 50px;
	min-height: 50px;
	width: 400px;
}

.searchRow {
	display: flex;
	flex-direction: row;
	margin-bottom: 1%;
	align-items: center;

	justify-content: space-between;
}

.searchCheckbox {
	margin-right: 0.25rem;
}

.searchCheckboxLabel {
	margin-right: 1%;
}

.resultsAmount {
	margin-left: 10%;
	margin-top: 1%;
	font-size: 16px;
	margin-right: 20%;
}

.searchResultBTN {
	background: var(--silver);
}

.active {
	background: var(--brand);
}
.searchResultBTN:hover {
	background: var(--brandHover);
}

.searchLabel {
	margin-bottom: 0.25rem;
	height: 18px;
}

.searchSelectCategory {
	padding: 0.25rem;
}

.searchContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	color: white;
	width: 80%;
	margin: 0 auto;
}

#searchForm {
	/* margin-left: 10%; */
}

.searchTitle {
	font-size: 28px;
	margin-bottom: 2rem;
}

.row {
	display: flex;
	flex-direction: row;
}

.slick-track {
	display: flex !important;
	gap: 10px;
}

.videoContainer {
	padding-top: 5%;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.details-description-container {
	width: 80%;
	margin: 0 auto;
	background-color: #1e2228;
	overflow: hidden;
	padding: 20px;
	box-sizing: border-box;
	margin-top: 30px;
}

.detailsDescriptionTitleContainer {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #fcca13;
	padding: 10px;
	overflow: hidden;
}

.details-description-title-left {
	display: flex;
	flex-direction: column;
	width: 70%;
	float: left;
}

.details-description-title-right {
	max-width: 30%;
	margin-left: auto;
}

.details-description-title-name {
	font-size: 30px;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	margin-right: auto;
}

.folderNamesList {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 5px;
}

.folderName {
	color: white;
	font-size: 20px;
	margin-right: 0.5rem;
}

.ratingElement {
	width: 50px;
}

.details-desription-mobile-cover {
	display: none;
}

.details-description-info-container {
	margin: 20px auto;
	display: flex;
}

.detailsContainer {
	font-family: "Nunito", sans-serif;
}

.detailsInfoContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.infoDataContainer {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	color: var(--silver);
}

.dataTitle {
	font-weight: bold;
	margin-right: auto;
}

.dataValue {
	margin-right: auto;
	padding: 10px 0px;
}

.shareBTN {
	background-repeat: no-repeat;
	background-size: cover;
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.details-share {
	display: flex;
	padding-top: 35px;
	flex-wrap: wrap;
	width: 100%;
}

.detailsDescriptionContainer {
	flex: 3;
}

.svg-triangle {
	width: 26px;
	height: 20px;
	transform: rotateZ(180deg);
}

.categoryGridContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.resultsGrid {
	width: 100%;
	margin-top: 1.5%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.categoryItem {
	/* max-width: 17rem; */
	transition: all 0.3s ease-in-out;
	margin: 0.5rem 0.25rem;
}

.promoContainer {
	width: 99%;
	margin: 0 auto;
	overflow: hidden;
}

.promoItem {
	box-sizing: border-box;
	width: 33.333%;
	padding: 0.2rem;
}

.promoItemImg {
	width: 100%;
	cursor: pointer;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.bannerItem {
	width: 100%;
	cursor: pointer;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.categoryItem:hover {
	cursor: pointer;
}

.footerLine {
	border-top: 2px solid #f6c917;
	margin: 20px 0;
}

.bottomBar {
	width: 100%;
	height: 120px;
	padding: 0 10% 1% 10%;
	display: table;
	color: white;
	z-index: 5;
}

.footerInfo {
	padding-left: 20px;
	display: inline-block;
}

.footerInfoName {
	font-size: 18px;
	margin-bottom: 15px;
}

.footerInfoAddress {
	font-size: 12px;
	float: left;
	margin-right: 180px;
}

.footerInfoPhoneEmail {
	display: inline-block;
	font-size: 12px;
}

.footerTos {
	font-size: 12px;
	float: right;
	padding-right: 20px;
}

.footerTos:hover {
	text-decoration: underline;
	cursor: pointer;
}

.tosContainer {
	margin: 0 auto 0 auto;
	width: 80%;
	flex-grow: 1;
	color: white;
	font-size: 12px;
}

.SVOD-container,
.TVOD-container {
	margin: 0.5rem;
	width: 10rem;
	height: 10rem;
	background-color: #fcca13;
	text-align: center;
}

.SVOD-container:hover,
.TVOD-container:hover {
	cursor: pointer;
}

.main-container {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 70.5%;
	justify-content: center;
}

.categoriesBar {
	display: flex;
	margin-top: 1rem;
	width: 93%;
	margin-left: auto;
	margin-right: auto;
}

.categoriesButtonContainer {
	display: inline-block;
	position: relative;
	color: white;
	font-size: 24px;
}

.categoriesButton {
	display: flex;
	position: relative;
	align-items: center;
	cursor: pointer;
}

.svg-chevron {
	margin-left: 0.5rem;
}

.dropdownOption {
	padding: 1rem;
	text-align: start;
	width: 100%;
	cursor: pointer;
	text-transform: capitalize;
}

.dropdownOption:hover {
	background: #313131;
}

.loginContainer,
.signUpContainer {
	cursor: pointer;
	display: flex;
}

.loginContainer > * + * {
	margin-left: 10px;
}
.modal-background {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 10, 10, 0.5);
	cursor: pointer;
	position: absolute;
	z-index: 110;
}

.loginFormContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 1.75rem auto;
	position: relative;
	background-color: #3d3d3d;
	border-radius: 0.25rem;
	padding: 1rem 2rem 1rem 2rem;
	max-width: 30rem;
	max-height: 20rem;
	z-index: 120;
	cursor: default;
}

#form_id {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.loginFormInput {
	width: 100%;
	margin: 0.25rem 0 0.25rem 0;
}

.signUpBarInput {
	padding-left: 0.5rem;
	width: 100%;
	margin-right: 0.25rem;
}

.signUpBar {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	color: white;
	align-items: center;
}

.signUpBar2 {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	color: white;
	align-items: center;
	padding: 20px auto;
}

.signUpBackground {
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	height: 20rem;
	width: 100%;
	z-index: 1;
	padding: 0;
	margin: 0;
}

.signUpWelcomeText {
	font-size: 24px;
	width: 33.3%;
	z-index: 2;
	margin-top: 2rem;
}

.signUpWelcomeText2 {
	margin-left: auto;
	margin-right: auto;
	font-size: 48px;
	z-index: 2;
	margin-top: 2rem;
}

.signUpWelcomeText3 {
	font-size: 28px;
	z-index: 2;
	margin-top: 2rem;
}

.signUpRow {
	display: flex;
	flex-direction: row;
	margin-top: 1%;
	z-index: 2;
}

.loginMessage {
	height: 4rem;
}

.loginFormTOS {
	margin: 0.5rem 0 0.5rem 0;
	font-size: 18px;
	font-weight: normal;
}

.loginViewBackground {
	background-size: cover;
	display: flex;
	flex-grow: 1;
	height: 100%;
	overflow: hidden;
	/*position: absolute;*/
	width: 100%;
	z-index: 1;
}

.loginViewContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.loginFormButtons {
	display: flex;
	flex-direction: row;
}

.loginFormBTN-background {
	background: #3d3d3d;
}

.loginFormBTN {
	background: lightgray;
	cursor: pointer;
	padding: 0.5rem;
	margin: 0 0.5rem 0 0;
	margin-left: 10%;
}

.loginFormBTN:hover {
	background: transparent;
}
/*
.signUpBarBTN-background {
  background: #3d3d3d;
}
*/

.signUpBarBTN {
	background: rgb(125, 221, 14);
	cursor: pointer;
	padding: 0.5rem;
	margin: 0 0.5rem 0 0;
	border-radius: 0.5rem;
	font-weight: 300;
	border: none;
	font-size: 38px;
}

.signUpBarInfoMessage {
	background: #f6c917;
	display: flex;
	font-size: 18px;
	z-index: 5;
	color: black;
	margin-top: 0.25rem;
	padding: 0.5rem;
	border-radius: 0.25rem;
}

.signUpBarBTN:hover {
	background: rgb(230, 229, 229);
	/* background: transparent;*/
}

.signUpFormBTN {
	background: lightgray;
	cursor: pointer;
	padding: 0.5rem;
	width: 80%;
	margin-left: 10%;
}

.signUpFormBTN:hover {
	background: rgb(230, 229, 229);
	/* background: transparent;*/
}

.signUpForm {
	display: flex;
	flex-direction: column;
	width: 25%;
	color: white;
}

.stepsContainer {
	font-size: 20px;
}

.signUpFormInput {
	padding: 0.5rem;
	margin-bottom: 0.5rem;
}

.signUpStep1 {
	display: flex;
	flex-direction: column;
}

.signUpStepText {
	font-size: 20px;
	margin-bottom: 0.5rem;
	width: 80%;
	margin-left: 10%;
}

.signUpStepTitle {
	font-size: 22px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: bold;
}

.loginFormTitle {
	font-size: 22px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: bold;
}

#userDetails-form {
	display: flex;
	flex-direction: column;
}

.profile-container {
	width: 80%;
	flex-grow: 1;
	padding-top: 5%;
	margin: auto;
	display: flex;
	flex-direction: row;
	color: white;
}

.profile-menu-option {
	font-size: 18px;
	padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.profile-menu-option:hover {
	cursor: pointer;
	box-shadow: 0 0 11px rgba(241, 241, 241, 0.2);
}

.profile-option-view {
	color: black;
	font-size: 16px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.profile-details-row {
	display: flex;
	align-items: center;
	margin: 0.5rem auto 0.5rem auto;
}

.inputColumn {
	display: flex;
	flex-direction: column;
	margin-bottom: 0.25rem;
}

.profileFormInput {
	width: 30rem;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.profileFormBTN {
	align-self: center;
	width: 5rem;
	margin-left: 10%;
}

.loginFirstMessage {
	font-size: 18px;
	margin: auto;
}

.profileMessage {
	margin: 2rem auto;
}

.series-seasons-container {
	box-sizing: border-box;
	width: 80%;
	margin: 0 auto;
	padding: 20px;
}

.series-seasons-header {
	font-family: "Nunito", sans-serif;

	color: white;
	padding: 20px 0;
}

.series-seasons-numbers {
	display: flex;
}
.series-seasons-numbers > a {
	margin-right: 5px;
}

.seasonNumber {
	background-color: var(--white);
	margin-right: 5px;
	color: var(--card);
	display: flex;
	padding: 10px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	width: 30px;
	height: 30px;
	border-radius: 4px;
	cursor: pointer;
	text-decoration: none;
}

.seasonNumber:hover {
	background-color: var(--brandHover);
}

.series-episode-list {
	width: 80%;
	margin: 0 auto;
}

.episodeDetailsMiddle {
	width: 67%;
	display: flex;
	flex-direction: column;
	padding: 15px;
}

.episodeDetailsMiddleTop {
	display: flex;
	flex-direction: row;
}

.episodeDetailsMiddleBottom {
	margin-top: 1rem;
}

.episodeDetailsLeft {
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	height: 100px;
	width: 175px;
}

.episodeDetailsRight {
	display: flex;
	width: 11%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.episodeDetailsItem {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
	background: var(--card);
	color: var(--silver);
	border-radius: 0.25rem;
	height: 120px;
	min-height: 100px;
	max-height: 100px;
}

.info-duration {
	margin-left: 20px;
}

.episode-list-img {
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	height: 100px;
	width: 175px;
}

.episode-list-img:hover {
	cursor: pointer;
}

.icon-play {
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}
.icon-play:hover {
	color: var(--brandHover);
	position: absolute;
	top: 50%;
	left: 50%;
	cursor: pointer;

	transform: translate(-50%, -50%);
}

.episode-list-description {
	color: white;
	font-family: "Nunito", sans-serif;

	font-size: 16px;
}

.thumbnail-container {
	position: relative;
	box-sizing: border-box;
	height: 100px;
	width: 175px;
}

.informPlaceholder {
	font-size: 28px;
	color: white;
	text-align: center;
	height: 70%;
}

.play {
	/* background-image: url('../src/images/button_play_inactive.png'); */
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	cursor: pointer;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
}

.langFlag {
	margin-right: 0.5rem;
	width: 40px;
	max-width: 40px;
	cursor: pointer;
}

.languageSelect {
	margin-left: auto;
	padding-top: 10px;
}
.categoriesContainer {
	padding-bottom: 50px;
	/* width: 95%; */
	margin: 0 auto;
}
.bannerContainer {
	width: 80%;
	margin: 0 auto;
	padding-bottom: 0px;
}
@media (max-width: 750px) {
	.bannerContainer {
		padding-bottom: 0px !important;
	}
	.categoriesContainer {
		max-width: 95%;
		margin: 0 5% 0 0;
	}
}

.loginContainer .active {
	background-color: var(--brandHover) !important;
	/* // height is same as navbar height */
	height: 50px;
}
