.wrapper-demo {
	flex: 1;
	width: 400px;
	max-width: 90vw;

	z-index: 200;
	/* background: var(--brand); */
}

/****-----start-wrapper-dropdown-2----****/
.wrapper-dropdown-2 {
	position: relative;
	padding: 17px 20px 17px 15px;
	cursor: pointer;
	outline: none;
	color: var(--brandHover);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	font-weight: 600;
	max-height: 50px;
	min-height: 50px;
}
.dropdown:before {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	right: 20px;
	top: -3%;
	margin-top: -6px;
	border-width: 0px 12px 12px 12px;
	border-style: solid;
	border-color: var(--brandHover) var(--background);
}

.wrapper-dropdown-2 .dropdown {
	/* Size & position */
	position: absolute;
	top: 136%;
	left: 0px;
	right: 0px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
	/* Styles */
	background: var(--brandHover);
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	list-style: none;
	z-index: 1;

	/* Hiding */
	opacity: 0;
	pointer-events: none;
}
.wrapper-dropdown-2 .dropdown li:last-child {
	border-bottom: none;
}
.wrapper-dropdown-2 .dropdown li {
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.wrapper-dropdown-2 .dropdown li div {
	display: block;
	text-decoration: none;
	color: var(--mainWhite);
	padding: 15px;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	text-transform: capitalize;
	font-weight: 600;
}
.dropdown li div:hover {
	/* color: var(--brand); */
}
.dropdown li div:hover .icon {
	background-position: 0px 0px;
}
/* Hover state */
.wrapper-dropdown-2 .dropdown li:hover {
	background: var(--mainBlack);
}
.wrapper-dropdown-2 .dropdown li:nth-child(1) {
	border-top-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	-moz-border-top-left-radius: 5px;
	-o-border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-top-right-radius: 5px;
	-o-border-top-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:last-child {
	border-bottom-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-bottom-left-radius: 5px;
	-o-border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-bottom-right-radius: 5px;
	-o-border-bottom-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:nth-child(2),
.wrapper-dropdown-2 .dropdown li:nth-child(3),
.wrapper-dropdown-2 .dropdown li:nth-child(4) {
	border-radius: none;
}
/* Active state */
.wrapper-dropdown-2.active .dropdown {
	opacity: 1;
	pointer-events: auto;
	max-height: 25rem;
	overflow: scroll;
}
.active {
	/* background: var(--brandHover) !important; */
	color: var(--mainWhite);
}

.wrapper {
	margin: 20px auto;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	/* VIA CSS MATIC https://goo.gl/cIbnS */
	-webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
	overflow-y: auto;
}

.info {
	display: flex;
	align-items: center;
}
